import React from 'react';
import './App.scss';
import { Header } from 'Header/Header';

export const App: React.FC = () => {
  return (
    <div className="homepage">
      <Header/>
      <div className='homepage-container'>
      <p className='homepage-title'>
         Bienvenue sur Kompri
      </p>
      <p className='homepage-subtitle'>
         N'apprenez plus, comprenez ! 
      </p>
      </div>
    </div>
  );
}

export default App;
