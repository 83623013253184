import React from "react";
import logo from "resources/images/kompri-logo.png"

import "./Header.scss"


export const Header: React.FC = () => {

    return(
        <div className="header-container">
            <img src={logo} alt="kompri's logo" className="kompri-logo"/>
        </div>
    )
}